/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.main {
    border-radius: 18px;
    background: -webkit-gradient(linear, left top, right top, from(#353d84), to(#252b62));
    background: -o-linear-gradient(left, #353d84 0%, #252b62 100%);
    background: linear-gradient(90deg, #353d84 0%, #252b62 100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    margin: 0 19px;
    width: 311px;
    min-height: 94px;
    min-width: calc(100% - 40px);
}
.main span {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

.main {
    margin-top: -42px;;
}

.progressLine {
    height: 4px;
    width: 100%;
}

.lock {
    width: 20px;
    height: 20px;
}

.coins {
    margin: 10px 0 18px;
    position: relative;
}

.coins::before {
    content: ' ';
    background-image: url(../../../../../public/assets/images/Coin2.png);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    left:-20px;
    top:47.5%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
}

@media (max-height:720px) {
    .main {
        
        margin-top: -60px;
        height: 110px;
        width: calc(100% - 40px)!important;
    }
}