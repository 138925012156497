.main {
    background-image: url(../../assets/sprites/test.png);
    background-position: center;
    background-size: 100%;
    background-color: #010727;
    padding-top: 20px;
    height: 100vh;
   
}

.title {
    text-align: center;
    text-transform: uppercase;
    margin: 30px 0;
}