.container {
    padding: 0 9px;
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.menu {
    padding: 0.75em 1em;

    border-radius: 1.25em;
    position: relative;

    background: radial-gradient(50% 50% at 50% 50%, rgba(49, 41, 103, 0.2) 0%, rgba(86, 70, 180, 0.2) 100%);
}

.item:first-child {
    color:#FEFEFE78;
    padding: .5em;
}

.menuWrapper {
    display: flex;

    justify-content: space-around;
    align-items: center;

    overflow-x: auto;

    gap: .5em;
}

.menuWrapper > .item {
    display: block;

    color: white;
    text-decoration: none;

    font-weight: bolder;
    font-style: italic;

    text-transform: uppercase;

    font-size: 0.9em;
    cursor: default!important;
}

.menuWrapper > .item.active {
    padding: .5em 1em;

    background-color: #353D84;
    border-radius: 1.25em;
}

.menuWrapper > .item.disabled {
    pointer-events: none;

    color: #fff;
    opacity: 0.4;
}

.cloud {
    height: 30px;
    border-radius: 1.25em;
    background-color: #353d84;
    position: absolute;
    z-index: 0;
    top:50%;
    transform: translateY(-50%);
}

.item {
    position: relative;
    z-index: 20;
}
