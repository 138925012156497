/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-row-gap: .25em;
    overflow: auto;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; 
}
.container {
    padding: 0 20px;
    height: 220px;
}

.active {
    position: absolute;
    bottom:15vh;
    left:50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: calc(100% - 40px);
    display: none;
}

@media (max-width:350px) {
    .active {
        bottom: 12vh;
    }
}

@media (max-height:550px) {
    .active {
        bottom: 13vh;
    }
}