/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;

        -ms-flex-pack: justify;

            justify-content: space-between;

    padding: 1em;

    background: -webkit-gradient(linear, left top, right top, from(rgba(53, 61, 132, 0.81)), to(rgba(26, 30, 79, 0.81)));

    background: -o-linear-gradient(left, rgba(53, 61, 132, 0.81) 0%, rgba(26, 30, 79, 0.81) 100%);

    background: linear-gradient(90deg, rgba(53, 61, 132, 0.81) 0%, rgba(26, 30, 79, 0.81) 100%);

    border-radius: 1em;
    
}

.card span {
    display: block;
}

.coin {
    width: 20px;
    height: 20px;
}

.score {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap:10px;
}

.active {
    background: #00FF60;
    color:#010134!important;
}
.active * {
    color:#010134;
}