

.coinImage {
    width: 30px;
}


@keyframes firS {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translateY(-100px);
    }
}

@keyframes secS {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translateY(-100px);
    }
}

@keyframes thirS {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translateY(-100px);
    }
}