.container {
    background-image: url(../../assets/sprites/test.png);
    background-position: center;
    background-size: 100%;
    background-color: #010727;
    padding-top: 20px;
    height: 100vh;
    display: flex;

    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 68px;
}

.userHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:9px;
    width: 100%;
    text-decoration: none;
}

.userHeader span {
    text-decoration: none;
}

.userHeader img {
    border-radius: 100%;
}

.userHeader span {
    font-weight: 700;
font-size: 20px;
color: #fff;
}
.userHeader img {
    width: 33px;
    height: 33px;
}

.button {
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;
    color: #00ff60;
    padding: 10px 36px;
    background: linear-gradient(90deg, #353d84 0%, #1d2255 100%);
    border-radius: 17px;
    border:none;
    margin: 0vh 0 2vh;
}

.text {
    font-weight: 600;
font-size: 14px;
text-align: center;
color: #fff;
max-width: 320px;
margin-bottom: 2vh;
}

.rewardsButton {
    font-weight: 600;
font-size: 16px;
text-transform: uppercase;
text-align: center;
color: #fff;
padding: 12px 36px;
background: linear-gradient(90deg, #353d84 0%, #1b2052 100%);
border-radius: 17px;
margin: 0vh 0 1.5vh;
border:none;
}



.claimButton {
    background: #00FF60;
    padding: 5px 0;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:20px;
    height: 43.33px;
    border-radius: 18px;
    margin-bottom: 1.5vh;
}
.text {
    width: 100%;
}

.smallCoin {
    width: 18px;
    margin: 0 3px;
}

