.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'MonBold';
  src: url("./assets/fonts/Montserrat-Bold.woff") format('.woff');
  src: url("./assets//fonts/Montserrat-Bold.ttf");
}

@font-face {
	font-family: 'Boldik';
	src: url('./assets/fonts/Aeroport-Bold.woff2') format('woff2'), url('./assets/fonts/Aeroport-Bold.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'MonBold';
  color: #fff;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

body {
  overflow: hidden;
  height: 100vh;
  position: fixed; 
  width: 100vw;
}


.fir {

  animation: fir 2.6s ease;
}

.sec {
  animation: sec 3.3s ease;
}

.thir {
  animation: thir 1.8s ease;
}

.fiv {
  animation: fiv 3.7s ease;
}
@keyframes coin {
  0% {
      transform: scale(0) translateY(-20px);
  }
  50% {
      transform: scale(1) translateY(-70px);
      margin-bottom: 5px;
      opacity: 1;
  }
  100%{
      opacity: 0;
  }
}

@keyframes fir {
  0% {
      transform: scale(0) translateY(-20px);
  }
  50% {
      transform: scale(1) translateY(-100px);
      margin-bottom: 5px;
      opacity: 1;
  }
  100%{
     
  }
}

@keyframes sec {
  0% {
      transform: scale(0) translateY(-20px);
  }
  50% {
      transform: scale(1) translateY(-40px);
      margin-bottom: 5px;
      opacity: 1;
  }
  100%{
     
  }
}

@keyframes thir {
  0% {
      transform: scale(0) translateY(-20px);
  }
  50% {
      transform: scale(1) translateY(-78px);
      margin-bottom: 5px;
      opacity: 1;
  }
  100%{
     
  }
}

@keyframes fiv {
  0% {
      transform: scale(0) translateY(-20px);
  }
  50% {
      transform: scale(1) translateY(-58px);
      margin-bottom: 5px;
      opacity: 1;
  }
  100%{
     
  }
}