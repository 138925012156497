.wallet {
    display: flex;
    gap:5px;
    position: absolute;
    top:92px;
    align-items: center;
    left:50%;
    transform: translateX(-50%);
}

.tonConnect {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100% !important;
    display: flex !important;
    opacity: 0;
}

.tonConnect * {
    width: 100%!important;
    display: flex!important;
}

.wallet {
    display: flex;
    justify-content: center;
    align-items: center;
}

.connectWallet {
    background: none;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
}

.ton {
    width: 19px;
}
