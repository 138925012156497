.action {
    font-weight: 700;
font-size: 16px;
text-transform: uppercase;
color: #fff;
}
.name {
    font-weight: 500;
font-size: 16px;
text-transform: uppercase;
color: #fff;
}

.item {
    padding: 14px 20px;
    border-radius: 18px;
    background: linear-gradient(90deg, #2b3275 0%, #1a1e4f 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.item span {
    /* font-family: 'MonExtraBold', sans-serif!important; */
}

.coin {
    width: 18px;
}

.linkContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
}

.item {
    text-decoration: none!important;
}

.done {
    opacity: 0.8;
}

.done button {
    background: #353D84;
    color:#fff;
    width: 68.5px;
}
.active {
    background: linear-gradient(90deg, #00ff60 0%, #01c84c 100%);
}

.active span {
    color: #000 !important;;
}

.textCont {
    display: flex;
    flex-direction: column;
    gap:5px;
}

.statusCont button {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    
    padding: 5px 7px;
    border-radius: 13px;
    
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68.5px;
}

.claim {
    color: #2b3275;
    background: #01ff60;
}

.work {
    background: #2b3275;
    color: #01ff60;
}

.doneClaim {
    background: #2b3275;
    color: #fff;
}