.main {
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(90deg, #353d84 0%, #252b62 100%);
    border-radius: 20px;
    width: 90%;
    height: 347px;
    padding: 13px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1vh;
    z-index: 1500;
    display: none;
}

.active {
    display: flex;
}

.gameOverHeader {
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;
    color: #00ff60;
    text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.coin {
    width: 45px;
height: 45px;
}

.coinsCont {
    z-index: 1500;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}



.coinsContainer {
    position: absolute;
    z-index: 1500;
    left:50%;
    top:30%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.coins {

    width: 30px;
    height: 30px;
}

.coinsContainer {
   
    width: 200px; /* Размер контейнера */
    height: 200px; /* Размер контейнера */
    margin: 0 auto; /* Центрирование контейнера */
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes flyOut {
    0% {
        transform: rotate(var(--angle, 0deg)) translate(0px) rotate(calc(-1 * var(--angle, 0deg)));
        opacity: 1;
    }
    
    99.8% {
        transform: rotate(var(--angle, 0deg)) translate(150px) rotate(calc(-1 * var(--angle, 0deg)));
        opacity: 0;
    }
    100% {
        transform: rotate(var(--angle, 0deg)) translate(0px) rotate(calc(-1 * var(--angle, 0deg)));
        opacity: 0;
    }
}


.coinWrapper {
    position: absolute;
    width: 30px; /* Размер каждого элемента */
    height: 30px; /* Размер каждого элемента */
    transform-origin: 50% 50%;
    animation: flyOut 2s ease;
}


.coinText {
    font-weight: 800;
    font-size: 40px;
    text-transform: uppercase;
    color: #00ff60;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    letter-spacing: 1px;
}
.button img {
    width: 20px;
    height: 20px;
}

.button span {
    display: flex;
    align-items: center;
    gap:5px;
    color:#000;
}

.button {
    background: linear-gradient(90deg, #00ff60 0%, #01c84c 100%);
    border-radius: 18px;
    width: 238px;
    text-wrap: nowrap;
    height: 52px;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    text-decoration: none;
    border:none;
}

.diceText {
    position: absolute;
    bottom:20px;
    font-size: 18px;
    text-transform: uppercase;
}