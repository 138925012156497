.copyImage {
    width: 16px;
}

.idUser {
    font-size: 20px;
    margin: 2vh 0;
    display: flex;
    align-items: center;
    gap:4px;
    background: none;
    border:none;
}