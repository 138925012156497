.main {
    background: url(../../../../public/assets/sprites/test.webp);
    background-size: 100%;
    background-position: center;
    position: absolute;
    z-index: 10000;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    
}

.procesing_ring {
    stroke: #5297ff;
    stroke-width: 2vw; /* Толщина линии */
    fill: transparent; /* Прозрачное заполнение */
}

.loadCircle {
    width: 62vw;
    height: 62vw;
    border-radius: 100%;
    position: absolute;
    top: 50.8%;
    left: 49.8%;
    transform: rotate(-90deg) translate(50%, -50%) scaleY(1.01);
    box-shadow: -4px 0px 4px #00000025;
 
}

.progress {
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}