.main {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 306px;
    height: 164px;
    background: linear-gradient(90deg, rgba(53,61,132,1) 0%, rgba(37,43,98,1) 100%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.hearts {
    display: flex;
}

.hearts img {
    width: 68px;
    height: auto;
}
.button {
    background: #01FF60;
    color:#2B3275;
    border:none;
    border-radius: 15px;
    padding: 5px 10px;
}