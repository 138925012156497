/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.scene {
    width: 300px;
    height: 300px;
    -webkit-perspective: 800px;
            perspective: 800px;
    position: absolute;
    top:60%;
    left:50%;
    -webkit-transform: translate(-50%, -50%) scale(0.60);
        -ms-transform: translate(-50%, -50%) scale(0.60);
            transform: translate(-50%, -50%) scale(0.60);
    z-index: 10000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .cube {
    width: 200px;
    height: 200px;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .cube--disabled {
    pointer-events: none;
  }
  
  .cube__face {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid #ddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
            box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .dot {
    width: 30px;
    height: 30px;
    background-color: black;
    border-radius: 50%;
    background: -o-radial-gradient(center, circle, #000 30%, #666 60%, #333 70%);
    background: radial-gradient(circle at center, #000 30%, #666 60%, #333 70%);
    -webkit-box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.8);
            box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.8);
    position: absolute;
  }
  
  .face-1 .dot {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .face-1 .dot {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.face-2 .dot:nth-child(1) {
    top: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-2 .dot:nth-child(2) {
    bottom: 25%;
    right: 25%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}

.face-3 .dot:nth-child(1) {
    top: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-3 .dot:nth-child(2) {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-3 .dot:nth-child(3) {
    bottom: 25%;
    right: 25%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}

.face-4 .dot:nth-child(1) {
    top: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-4 .dot:nth-child(2) {
    top: 25%;
    right: 25%;
    -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}
.face-4 .dot:nth-child(3) {
    bottom: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}
.face-4 .dot:nth-child(4) {
    bottom: 25%;
    right: 25%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}

.face-5 .dot:nth-child(1) {
    top: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-5 .dot:nth-child(2) {
    top: 25%;
    right: 25%;
    -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}
.face-5 .dot:nth-child(3) {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-5 .dot:nth-child(4) {
    bottom: 25%;
    left: 25%;
    -webkit-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}
.face-5 .dot:nth-child(5) {
    bottom: 25%;
    right: 25%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}

.face-6 .dot:nth-child(1) {
    top: 20%;
    left: 20%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-6 .dot:nth-child(2) {
    top: 50%;
    left: 20%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-6 .dot:nth-child(3) {
    top: 80%;
    left: 20%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.face-6 .dot:nth-child(4) {
    bottom: 80%;
    right: 20%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}
.face-6 .dot:nth-child(5) {
    bottom: 50%;
    right: 20%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}
.face-6 .dot:nth-child(6) {
    bottom: 20%;
    right: 20%;
    -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
}

  
  .cube__face--front  { -webkit-transform: rotateY(  0deg) translateZ(100px); transform: rotateY(  0deg) translateZ(100px); }
  .cube__face--back   { -webkit-transform: rotateY(180deg) translateZ(100px); transform: rotateY(180deg) translateZ(100px); }
  .cube__face--right  { -webkit-transform: rotateY( 90deg) translateZ(100px); transform: rotateY( 90deg) translateZ(100px); }
  .cube__face--left   { -webkit-transform: rotateY(-90deg) translateZ(100px); transform: rotateY(-90deg) translateZ(100px); }
  .cube__face--top    { -webkit-transform: rotateX( 90deg) translateZ(100px); transform: rotateX( 90deg) translateZ(100px); }
  .cube__face--bottom { -webkit-transform: rotateX(-90deg) translateZ(100px); transform: rotateX(-90deg) translateZ(100px); }
  