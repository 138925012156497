.main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: -30px;
    margin: 0 auto;
}

.list {
    width: 100%;
    max-width: 310px;
    height: 43vh;
    overflow: auto;
    list-style: disc;
    list-style: circle;
    gap: 6px;
    display: flex;
    flex-direction: column;
    
}

.list::-webkit-scrollbar {
    display: none;
}

.item {
    width: 100%;
    position: relative;
   
}

/* .item::before {
    content: ' ';
    background: #fff;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    display: inline-block;
    margin-right:-10px;
    position: absolute;

} */
.itemCont {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.itemCont span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.coin {
    width: 18px;
}

.active {
    width: 100%;
    justify-content: space-between;
    display: flex;
}