.list {
    list-style: none;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    padding: 0 8px;
    gap:10px;
}