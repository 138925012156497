body:has(.page) {
    height: 100vh;
    overflow: hidden;

    overscroll-behavior: none !important;
    touch-action: none !important;
    -webkit-touch-callout: none !important;
}

.page {
    position: relative;
    z-index: 10000;
}
.body {
    position: absolute;
    top:0;
    left:0;
    z-index: 500;
}

.coins img{
    width: 55px;
    aspect-ratio: 1/1;
}
.body {
    width: 100%;
}
.coins {
    font-weight: 700;
    font-size: 38px;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    text-wrap: nowrap;
    justify-content: center;
    left:50%;
    top: 130px;
    transform:translateX(-50%);
}

.spanText {
    opacity: 0.5;
    color:#fff;
    top:-40px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 800;
    position: absolute;
    font-size:14px;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
}

.name img {
    width: 45px;
    height: 45px;
}
.name {
    display: flex;
    align-items: center;
    color:#fff;
    text-decoration: none;
    font-family: 'MonBold';
    position: absolute;
    top:10px;
    left:10px;
    gap:10px;
}
.button {

    bottom:100px;
    transition: all 0.3s ease;
    width: 80%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 33px;
    text-wrap: nowrap;
    color: #fff;
    text-transform: uppercase;
    padding: 11px 0px;
    background: #3A4390;
    border-radius: 15px;
    border:none;
}

.buttonParse {

    width: 80%;
    text-align: center;
    font-weight: 700;
    font-size: 33px;
    text-wrap: nowrap;
    color: #fff;
    text-transform: uppercase;
    
    background: #878787;
    border-radius: 15px;
    border:none;
    box-sizing: border-box;
    user-select: none;
    padding: 0;
}

.doneCont {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
}

.span {
    user-select: none !important; /* Для современных браузеров */
    -webkit-user-select: none!important; /* Для Safari и Chrome */
    -moz-user-select: none !important; /* Для Firefox */
    -ms-user-select: none !important;
}

.coinsCont {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:-10px;
    left:50%;
    transform: translateX(-50%) scale(1.1);
    width: 100%;
    justify-content: space-between;
    z-index: -1;

}
.coinsCont div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}





.timer {
    position: relative;
    z-index: 50;
    font-family: 'Boldik';
}

.coin {
    width: 20px;
    aspect-ratio: 1/1;
}

.downCont {
    position: absolute;
    left:50%;
    transform:translateX(-50%);
    bottom:100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.doneButton {
    
    width: 80%;
    user-select: none;
    text-align: center;
    font-weight: 700;
    font-size: 33px;
    text-wrap: nowrap;
    color: #fff;
    text-transform: uppercase;
    padding: 11px 30px;
    background: #3ba849;
    border-radius: 15px;
    border:none;
    transition: all 1s ease;
}

.buttonCont {
    padding: 11px 30px;
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 1s ease;
    border-radius: 15px;
    user-select: none !important;
    height: 66.7px;
    overflow: hidden;
}
.progressbar {
    position: absolute;
    left:0;
    border-radius: 15px;
    height: 100%;
    top:0;
    background: #77c69665;
    z-index: -1;
    z-index: 10;
    font-family: 'Boldik';
}
.page:has(.gameScreen[data-fullscreen="true"]) {
    position: fixed;
    z-index: 999;
}

.gameScreen {
    display: block;

    background-color: #010727;
}


.gameScreen[data-fullscreen="true"] {

}


.doneButton {
    width: 80%;
    transition: transform 2s ease, opacity 0.5s ease;
  }
  
  .doneButton.pressed {
    transform: scale(1.1) ; /* Увеличиваем кнопку */
    opacity: 0.7;
  }
  
  .doneButton.completed {
    transform: scale(1) ; /* Возвращаем кнопку в исходное состояние, если анимация завершена */
  }


  .coin {
    /* animation: coin 2s ease;  */
}

.gameover {
    z-index: 10000;
    position: absolute;
    background: linear-gradient(90deg, #353d84 0%, #252b62 100%);
    padding: 13px 40px;
}

.playButton {
    width: 77%;
    position: absolute;
    top: 51%;
    left: 50.5%;
    transform: translate(-50%, -50%);
}
.playButton .ballImg {
    width: 93%;
    position: absolute;
    top: 4%;
    left: 49%;
    transform: translate(-50%, -50.5%);
}

.claimCoin {
    width: 30px;
    height: 30px;
}

.textBall {
    top: 50%;
    position: absolute;
    left: 49.5%;
    transform: translate(-50%, -37%);
}

.textBall * {
    color: #000 !important;
}

.span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.textBall span {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.textBall img {
    width: 46px;
}

.textBall span:nth-child(1) {
    font-size: 58px;
}

.textBall span:nth-child(2) {
    font-weight: 700;
    font-size: 36px;
    color: #111215;
}

