/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;

        -ms-flex-pack: center;

            justify-content: center;
    margin-top:-72px;
}

.carouselWrapper {
    width: 100%;
}

.caruselItem {
    height: 55vh;
}

.carouselWrapper {
    position: relative;
}

.caruselItem div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.caruselItem img {
    /*filter: drop-shadow(1px 7px 23px #94BCD539);*/
    width: 250px;
    height: 250px;
    margin: auto;
}

.back, .next {
    background-image: url(../../../../assets/images/arrow.svg);
    height: 27px;
    width: 12.5px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 0;
    width: 50px;
    height: 100%;
    background-size: 17px;
    background-position: center;
    cursor: default;
}

.back {
    background-image: url(../../../../assets/images/back.svg);
    left:0px;
}

.carouselCont {
    position: relative;
}

.circle {
    top: 43%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    width: 255px;
    height: 226px;
    -webkit-filter: blur(16px);
            filter: blur(16px);
    border-radius: 100%;
    background: #94BCD550;
    z-index: -1;
    opacity: 0.4;
}

.pluto .circle{
    background: #B9B8B9;
}

.neptun .circle {
    background: #188BBB;
}

.uran .circle {
    background: #18B1BB;
}

.mars .circle {
    background: #A03A23;
}

.mercuri .circle {
    background: #A06723;
}

.venera .circle {
    background: #F69F37;
}

.jupiter .circle {
    background: #D6916A;
}

.earth .circle {
    background: #07A6FF;
}

.sun .circle {
    background: #FF9C07;
}

.saturn .circle {
    background: #D1914F;
}

@media (max-height:720px) {
    .caruselItem img{
        -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
                transform: scale(0.8);
        margin-bottom: 0;
    }
}