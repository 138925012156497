/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.title {
    font-family: "Inter", sans-serif;
    font-weight: bold;

    text-transform: uppercase;
    text-align: center;
    margin: 3vh 0 2vh;
}

.arrow {
    background: none;
    border:none;
    position: absolute;
    top:10px;
    left:10px;
    z-index: 10;
    
}

.arrow img {
    width: 50px;
    height: 50px;
}

.main {
    background-image: url(../../assets/sprites/test.png);
    background-position: center;
    background-size: 100%;
    background-color: #010727;
    padding-top: 1px;
    height:100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 95px;
}

.title {
    text-align: center;
    text-transform: uppercase;
}